var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('img',{staticClass:"bg",attrs:{"src":"https://cdn.qiumo.net.cn/allsmarketmaterial/changXueSupreme/top.png"}}),_c('div',{staticClass:"content"},[_c('ul',{staticClass:"line"},_vm._l((_vm.data),function(item,index){return _c('li',{class:[
          'line-item',
          {
            opacity:
              _vm.isActive && _vm.changXueSupreme_info.equityName !== item.equityName,
          },
          { active: _vm.changXueSupreme_info.equityName == item.equityName },
        ],on:{"click":function($event){return _vm.handleExchange(item)}}},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"icon",attrs:{"src":item.equityIcon}})]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(item.title1))]),_c('div',{staticClass:"text"},[_vm._v(_vm._s(item.title2))])])])}),0)]),_c('BaseFooter')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }