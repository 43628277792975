<template>
  <div class="container">
    <img
      class="bg"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/changXueSupreme/top.png"
    />
    <div class="content">
      <ul class="line">
        <li
          v-for="(item, index) in data"
          :class="[
            'line-item',
            {
              opacity:
                isActive && changXueSupreme_info.equityName !== item.equityName,
            },
            { active: changXueSupreme_info.equityName == item.equityName },
          ]"
          @click="handleExchange(item)"
        >
          <div class="card">
            <img class="icon" :src="item.equityIcon" />
          </div>
          <div class="bottom">
            <div class="text">{{ item.title1 }}</div>
            <div class="text">{{ item.title2 }}</div>
          </div>
        </li>
      </ul>
    </div>
    <BaseFooter />
  </div>
</template>

<script>
import BaseFooter from "@/components/BaseFooter/index";
import { equityList, exchange } from "@/api/changXueSupreme/index.js";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BaseFooter,
  },
  data() {
    return {
      data: [],
      active: {},
    };
  },
  computed: {
    ...mapGetters([
      "changXueSupreme_token",
      "changXueSupreme_info",
      "changXueSupreme_productNo",
    ]),
    isActive() {
      const { equityName = "" } = this.changXueSupreme_info;
      return !!equityName;
    },
  },
  async mounted() {
    await this["changXueSupreme/getUserInfo"]();
    await this.getItemInfo();
  },
  created() {
    const { success = false} = this.changXueSupreme_info;
    if (!success) {
     this.$dialog.alert({
        title: "提示",
        className: "vdialog",
        message: "您还未订购该业务,请先订购业务!",
        confirmButtonColor: "#01befd",
        confirmButtonText: "去订购",
      }).then(() => {
        location.href = 'https://asa.qiumo.net.cn/aspage/mangoPreferential/pageCXVip?from=qmqygj01'
      });
    }
  },
  methods: {
    ...mapActions(["changXueSupreme/getUserInfo"]),
    async handleSubmit() {
      this.$toast.loading({
        message: "领取中...",
        forbidClick: true,
        duration: 0,
        loadingType: "spinner",
      });
      try {
        const { equityName = "" } = this.active;
        const { code = "", message = "" } = await exchange(
          this.changXueSupreme_token,
          {
            equityName,
            productNo: this.changXueSupreme_productNo,
          }
        );
        this.$dialog.alert({
          title: "提示",
          className: "vdialog",
          confirmButtonColor: "#01befd",
          message: "领取成功,预计10分钟内发放至手机账号中",
        });
        if (code == 0) {
          this["changXueSupreme/getUserInfo"]();
        }
      } catch (err) {
        this.$dialog.alert({
          title: "提示",
          className: "vdialog",
          message: err.description || "系统繁忙,请稍后再试",
        });
      } finally {
        this.$toast.clear();
      }
    },
    async getItemInfo() {
      const { code = 0, data = [] } = await equityList(
        this.changXueSupreme_token
      );
      if (code == 0) {
        this.data = data;
      }
    },
    handleExchange(item) {
      this.active = item;

      const { content = "" } = this.active;
      const { success = false, equityName = "" } = this.changXueSupreme_info;

      if (!success) {
        // 未订购
        this.$dialog.alert({
          title: "提示",
          className: "vdialog",
          message: "您还未订购该业务,请先订购业务!",
          confirmButtonColor: "#01befd",
          confirmButtonText: "去订购",
        }).then(() => {
          location.href = 'https://asa.qiumo.net.cn/aspage/mangoPreferential/pageCXVip?from=qmqygj01'
        });
      } else if (success && !equityName) {
        //已订购未领取
        this.$dialog
          .confirm({
            title: "使用规则",
            className: "vdialog",
            message: content,
            messageAlign: "left",
            confirmButtonColor: "#01befd",
            confirmButtonText: "确认领取",
            cancelButtonColor: "#999999",
          })
          .then(async () => {
            this.handleSubmit();
          });
      } else if (success && !!equityName) {
        if (item.equityName === equityName) {
          // 已订购已领取
          this.$dialog.alert({
            title: "使用规则",
            className: "vdialog",
            message: content,
            messageAlign: "left",
            confirmButtonColor: "#01befd",
            confirmButtonText: "确定",
          });
        } else {
          this.$dialog
            .confirm({
              title: "提示",
              className: "vdialog",
              message: "您本月已领取完权益,请下月再来领取",
              confirmButtonColor: "#01befd",
              confirmButtonText: "查看使用规则",
              cancelButtonColor: "#999999",
            })
            .then(() => {
              this.$dialog.alert({
                title: "使用规则",
                className: "vdialog",
                message: content,
                messageAlign: "left",
                confirmButtonColor: "#01befd",
                confirmButtonText: "确定",
              });
            });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #f6f6f6;
  height: 100%;

  .content {
    position: absolute;
    left: 24px;
    right: 24px;
    top: 189px;
    bottom: 160px;
    background: #ffffff;
    border-radius: 18px;
    overflow-y: auto;

    .line {
      display: flex;
      flex-wrap: wrap;
      padding: 40px 0 100px 28px;
      align-content: flex-start;
      &-item {
        width: 202px;
        height: 279px;
        margin-bottom: 20px;
        text-align: center;
        position: relative;
        box-sizing: border-box;
        background: url("https://cdn.qiumo.net.cn/allsmarketmaterial/changXueSupreme/card.png")
          center center/contain no-repeat;

        &:not(:nth-child(3n)) {
          margin-right: 18px;
        }

        &.opacity {
          opacity: 0.5;
        }

        &.active {
          &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background: url("https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/memberBenefits/card_received.png")
              center center/cover no-repeat;
            border-radius: 16px;
          }
        }
        .card {
          height: 144px;
          // background-color: red;
          display: flex;
          justify-content: center;
          align-items: center;
          .icon {
            width: 86px;
            height: 86px;
          }
        }

        .bottom {
          padding: 26px 0 0;
          box-sizing: border-box;
          .text {
            font-weight: 400;
            line-height: 45px;
            font-size: 30px;
            color: #333333;
          }
        }
      }
    }
  }
  .bg {
    width: 100%;
  }
}
</style>

<style  lang="scss">
.vdialog {
  .van-dialog__header {
    padding-top: 40px;
    font-size: 34px;
    color: #000000;
    line-height: 48px;
  }
  // color: red;
  .van-dialog__message {
    color: red;
    line-height: 42px;
    color: rgba(10, 17, 26, 0.5);
  }
}
</style>
